import { PUBLIC_NODE_ENV } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://af3631e39f57fee0bbda85c9eb81ea4a@o4507697980702720.ingest.de.sentry.io/4507724432998480',
  tracesSampleRate: PUBLIC_NODE_ENV !== 'development' ? 0.2 : 1.0,
  environment: PUBLIC_NODE_ENV,
  // Toggle for testing
  enabled: PUBLIC_NODE_ENV !== 'development',
  integrations: [
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({ levels: ['error'] })
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

const myErrorHandler = ({ error, event }: any) => {
  console.error('An error occurred on the client side:', error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);
